<template>
  <div>
    <form
      class="add-form spot"
      ref="profile_form"
      @submit.prevent="handleSubmit"
      @keydown.enter.prevent="() => {}"
    >
      <div class="add-form-inner">
        <h2>{{ $t("SPOTS.SPOT_GENERAL_INFORMATION") }}</h2>

        <!-- Picture -->
        <div class="form-wrapper full">
          <image-selector
            :label="$t('COMMON.PICTURE')"
            :defaultImage="spot.picture"
            ressource_name="spots"
            :ressource_id="spot.id ? spot.id : 0"
            field="picture"
            @imageChanged="
              (file_url) => {
                spot.picture = file_url;
                onFormChanged();
              }
            "
          />
          <validation-error :errors="apiValidationErrors.attachment" />
        </div>

        <!-- Organization -->
        <div class="form-wrapper full">
          <base-input
            :label="`${$t('COMMON.ORGANIZATION')} (*)`"
            :placeholder="$t('COMMON.ORGANIZATION')"
            v-if="
              $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS) &&
              !hideOrganization
            "
          >
            <organization-selector
              :organization="spot.organization?.id"
              :filterable="true"
              :showAll="false"
              @organizationChanged="
                (organizationId) => {
                  spot.organization.id = organizationId;
                  onFormChanged();
                }
              "
              :disabled="!!spot.id"
            />
          </base-input>
          <validation-error :errors="apiValidationErrors.organization" />
        </div>

        <!-- Spot name -->
        <div class="form-wrapper">
          <base-input
            :label="`${$t('SPOTS.SPOT_NAME')} (*)`"
            v-model="spot.name"
            @change="
              () => {
                onFormChanged();
              }
            "
          />
          <validation-error :errors="apiValidationErrors.name" />
        </div>

        <!-- Spot number -->
        <div class="form-wrapper">
          <base-input
            :label="`${$t('SPOTS.SPOT_NUMBER')}`"
            v-model="spot.code"
            @change="onFormChanged"
          />
          <validation-error :errors="apiValidationErrors.code" />
        </div>

        <!-- Category & type -->
        <div class="form-wrapper">
          <base-input :label="`${$t(`COMMON.CATEGORY`)} (*)`">
            <spot-category-selector
              :spotCategory="spot.category.id"
              :filterable="true"
              :showAll="false"
              @spotCategoryChanged="onCategorySelected"
            />
          </base-input>
          <validation-error :errors="apiValidationErrors.category" />
        </div>

        <!-- Type -->
        <div class="form-wrapper">
          <base-input :label="`${$t(`SPOTS.SPOT_TYPE`)} (*)`">
            <spot-type-selector
              :spotType="spot.spotType?.id"
              :filterable="true"
              :showAll="false"
              :filterSpotCategory="spot.category?.id"
              @spotTypeChanged="
                (spotTypeId) => {
                  spot.spotType.id = spotTypeId;
                  onFormChanged();
                }
              "
            />
          </base-input>
          <validation-error :errors="apiValidationErrors.spotType" />
        </div>

        <!-- Excerpt -->
        <div class="form-wrapper full">
          <base-input
            :label="$t('COMMON.COMMENT')"
            :placeholder="$t('COMMON.COMMENT')"
          >
            <html-editor v-model="spot.excerpt" @change="onFormChanged()">
            </html-editor>
          </base-input>
          <validation-error :errors="apiValidationErrors.excerpt" />
        </div>
      </div>

      <div class="add-form-inner">
        <h2>{{ $t("SPOTS.SPOT_SPECIFIC_INFORMATION") }}</h2>
        <!-- Gallery -->
        <div class="form-wrapper full">
          <gallery-selector
            :label="$t('COMMON.GALLERY')"
            :defaultGallery="spot.gallery"
            ressource_name="spots"
            :ressource_id="spot.id ? spot.id : 0"
            field="gallery"
            @galleryChanged="
              (gallery_urls) => {
                spot.gallery = gallery_urls;
                onFormChanged();
              }
            "
          />
        </div>

        <!-- status -->
        <div class="form-wrapper width_1-3">
          <base-input :label="`${$t('COMMON.STATUS')} (*)`">
            <el-select
              :label="$t('COMMON.STATUS')"
              :placeholder="$t('COMMON.STATUS')"
              v-model="spot.status"
              @change="
                (status) => {
                  spot.status = status;
                  onFormChanged();
                }
              "
            >
              <el-option
                v-for="(value, key) in spotStatusOptions"
                :key="key"
                :value="value"
                :label="$t(`SPOTS.SPOT_STATUS_${value}`)"
              />
            </el-select>
          </base-input>

          <validation-error :errors="apiValidationErrors.status" />
        </div>

        <!-- unit price -->
        <div class="form-wrapper width_1-3">
          <base-input
            :label="`${$t('COMMON.PRICE')}`"
            v-model="spot.unit_price"
            @change="
              () => {
                onFormChanged();
              }
            "
          />
        </div>

        <!-- person capacity -->
        <div class="form-wrapper width_1-3">
          <base-input
            :label="`${$t('SPOTS.SPOT_CAPACITY')} (*)`"
            v-model="spot.capacity"
            type="number"
            step="1"
            @change="
              (e) => {
                spot.adults_capacity = e.target.value;
                spot.children_capacity = e.target.value - 1;
              }
            "
          >
          </base-input>
          <validation-error :errors="apiValidationErrors.capacity" />
        </div>

        <!-- adult capacity -->
        <div class="form-wrapper width_1-3">
          <base-input
            :label="`${$t('SPOTS.SPOT_ADULT_CAPACITY')} (*)`"
            v-model="spot.adults_capacity"
            type="number"
            step="1"
          >
          </base-input>

          <validation-error :errors="apiValidationErrors.adults_capacity" />
        </div>

        <!-- children capacity -->
        <div class="form-wrapper width_1-3">
          <base-input
            :label="`${$t('SPOTS.SPOT_CHILDREN_CAPACITY')} (*)`"
            v-model="spot.children_capacity"
            type="number"
            step="1"
          >
          </base-input>

          <validation-error :errors="apiValidationErrors.children_capacity" />
        </div>

        <!-- pets capacity -->
        <div class="form-wrapper width_1-3">
          <base-input
            :label="`${$t('SPOTS.SPOT_PETS_CAPACITY')} (*)`"
            v-model="spot.pets_capacity"
            type="number"
            step="1"
          >
          </base-input>

          <validation-error :errors="apiValidationErrors.pets_capacity" />
        </div>

        <!-- equipment length -->
        <div
          v-if="islandFormType || isSeasonalFormType"
          class="form-wrapper width_1-3"
        >
          <base-input
            :label="`${$t('SPOTS.EQUIPMENT_LENGTH')}`"
            type="number"
            v-model="spot.equipment_length"
            @change="onFormChanged"
          />
          <validation-error :errors="apiValidationErrors.equipment_length" />
        </div>

        <!-- spot area -->
        <div
          v-if="islandFormType || isSeasonalFormType"
          class="form-wrapper width_1-3"
        >
          <base-input
            :label="`${$t('SPOTS.SPOT_AREA')} (${$t(
              'COMMON.UNIT_SQUARE_FOOT'
            )})`"
            type="number"
            v-model="spot.spot_area"
            @change="onFormChanged"
          />
          <validation-error :errors="apiValidationErrors.spot_area" />
        </div>

        <!-- passenger extensions -->
        <div
          v-if="islandFormType || isSeasonalFormType"
          class="form-wrapper width_1-3"
        >
          <base-input :label="`${$t('SPOTS.PASSENGER_EXTENSIONS')}`">
            <el-select
              v-model="spot.passenger_extensions"
              :placeholder="`${$t('SPOTS.PASSENGER_EXTENSIONS')}`"
              @change="onFormChanged"
            >
              <el-option
                v-for="n in 10"
                :key="n"
                :value="n - 1"
                :label="n - 1"
              />
            </el-select>
          </base-input>
          <validation-error
            :errors="apiValidationErrors.passenger_extensions"
          />
        </div>

        <!-- driver extensions -->
        <div
          v-if="islandFormType || isSeasonalFormType"
          class="form-wrapper width_1-3"
        >
          <base-input :label="`${$t('SPOTS.DRIVER_EXTENSIONS')}`">
            <el-select
              v-model="spot.driver_extensions"
              :placeholder="`${$t('SPOTS.DRIVER_EXTENSIONS')}`"
              @change="onFormChanged"
            >
              <el-option
                v-for="n in 10"
                :key="n"
                :value="n - 1"
                :label="n - 1"
              />
            </el-select>
          </base-input>
          <validation-error :errors="apiValidationErrors.driver_extensions" />
        </div>

        <!-- allowed_equipment -->
        <div
          v-if="islandFormType || isSeasonalFormType"
          class="form-wrapper width_1-3"
        >
          <base-input :label="`${$t('SPOTS.ALLOWED_EQUIPMENT')}`">
            <allowed-equipment-selector
              :value="spot.allowed_equipment"
              @valueChanged="(value) => (spot.allowed_equipment = value)"
              multiple
            />
          </base-input>
          <validation-error :errors="apiValidationErrors.allowed_equipment" />
        </div>

        <!-- beds_king_qty -->
        <div v-if="isPropertyFormType" class="form-wrapper width_1-3">
          <base-input
            :label="`${$t('SPOTS.BEDS_KING_QTY')}`"
            type="number"
            v-model="spot.beds_king_qty"
            @change="onFormChanged"
          />
          <validation-error :errors="apiValidationErrors.beds_king_qty" />
        </div>

        <!-- beds_queen_qty -->
        <div v-if="isPropertyFormType" class="form-wrapper width_1-3">
          <base-input
            :label="`${$t('SPOTS.BEDS_QUEEN_QTY')}`"
            type="number"
            v-model="spot.beds_queen_qty"
            @change="onFormChanged"
          />
          <validation-error :errors="apiValidationErrors.beds_queen_qty" />
        </div>

        <!-- beds_double_qty -->
        <div v-if="isPropertyFormType" class="form-wrapper width_1-3">
          <base-input
            :label="`${$t('SPOTS.BEDS_DOUBLE_QTY')}`"
            type="number"
            v-model="spot.beds_double_qty"
            @change="onFormChanged"
          />
          <validation-error :errors="apiValidationErrors.beds_double_qty" />
        </div>

        <!-- beds_twin_qty -->
        <div v-if="isPropertyFormType" class="form-wrapper width_1-3">
          <base-input
            :label="`${$t('SPOTS.BEDS_TWIN_QTY')}`"
            type="number"
            v-model="spot.beds_twin_qty"
            @change="onFormChanged"
          />
          <validation-error :errors="apiValidationErrors.beds_twin_qty" />
        </div>

        <!-- beds_total_qty -->
        <div v-if="isPropertyFormType" class="form-wrapper width_1-3">
          <base-input
            :label="`${$t('SPOTS.BEDS_TOTAL_QTY')}`"
            type="number"
            v-model="spot.beds_total_qty"
            @change="onFormChanged"
          />
          <validation-error :errors="apiValidationErrors.beds_total_qty" />
        </div>

        <!-- bathroom_shower_qty -->
        <div v-if="isPropertyFormType" class="form-wrapper width_1-3">
          <base-input
            :label="`${$t('SPOTS.BATHROOM_SHOWER_QTY')}`"
            type="number"
            v-model="spot.bathroom_shower_qty"
            @change="onFormChanged"
          />
          <validation-error :errors="apiValidationErrors.bathroom_shower_qty" />
        </div>

        <!-- bathroom_bathtub_qty -->
        <div v-if="isPropertyFormType" class="form-wrapper width_1-3">
          <base-input
            :label="`${$t('SPOTS.BATHROOM_BATHTUB_QTY')}`"
            type="number"
            v-model="spot.bathroom_bathtub_qty"
            @change="onFormChanged"
          />
          <validation-error
            :errors="apiValidationErrors.bathroom_bathtub_qty"
          />
        </div>

        <!-- arrival time -->
        <div v-if="!isSeasonalFormType" class="form-wrapper width_1-3 time">
          <base-input
            :label="`${$t('SPOTS.SPOT_ARRIVAL_TIME')} (*)`"
            :placeholder="$t('SPOTS.SPOT_ARRIVAL_TIME')"
            v-model="spot.arrival_time"
            @change="onFormChanged"
          >
            <flat-picker
              :placeholder="$t('SPOTS.SPOT_ARRIVAL_TIME')"
              :config="{
                enableTime: true,
                noCalendar: true,
                dateFormat: 'H:i',
              }"
              class="form-control datepicker"
              v-model="spot.arrival_time"
              @on-change="onFormChanged()"
            >
            </flat-picker>
          </base-input>

          <validation-error :errors="apiValidationErrors.arrival_time" />
        </div>

        <!-- departure time -->
        <div v-if="!isSeasonalFormType" class="form-wrapper width_1-3 time">
          <base-input
            :label="`${$t('SPOTS.SPOT_DEPARTURE_TIME')} (*)`"
            :placeholder="$t('SPOTS.SPOT_DEPARTURE_TIME')"
            v-model="spot.departure_time"
            @change="onFormChanged"
          >
            <flat-picker
              :placeholder="$t('SPOTS.SPOT_DEPARTURE_TIME')"
              :config="{
                enableTime: true,
                noCalendar: true,
                dateFormat: 'H:i',
              }"
              class="form-control datepicker"
              v-model="spot.departure_time"
              @on-change="onFormChanged()"
            >
            </flat-picker>
          </base-input>

          <validation-error :errors="apiValidationErrors.departure_time" />
        </div>

        <!-- service included -->
        <div
          v-if="islandFormType || isSeasonalFormType"
          class="form-wrapper width_1-3"
        >
          <base-input :label="`${$t('SPOTS.SERVICES_INCLUDED')}`">
            <service-included-selector
              :value="spot.services_included"
              @valueChanged="(value) => (spot.services_included = value)"
              multiple
            />
          </base-input>

          <validation-error :errors="apiValidationErrors.adults_capacity" />
        </div>

        <!-- electricity -->
        <div
          v-if="islandFormType || isSeasonalFormType"
          class="form-wrapper width_1-3"
        >
          <base-input :label="`${$t('SPOTS.ELECTRICITY')}`">
            <electricity-selector
              :value="spot.electricity"
              @valueChanged="(value) => (spot.electricity = value)"
              multiple
            />
          </base-input>

          <validation-error :errors="apiValidationErrors.adults_capacity" />
        </div>

        <!-- region Compteur electrique -->
        <!-- le kilo watt -->
        <div v-if="isSeasonalFormType" class="form-wrapper width_1-3">
          <base-input
            min="0"
            type="number"
            label="Kilowatt"
            @change="onFormChanged"
            v-model="spot.kilo_watt"
            :placeholher="$t('SPOTS.KILOWATT')"
          >
          </base-input>
          <validation-error :errors="apiValidationErrors.kilo_watt" />
        </div>
        <!-- la date de mesure -->
        <div v-if="isSeasonalFormType" class="form-wrapper width_1-3">
          <base-input label="Date de la mesure du Kilowatt" class="date">
            <el-date-picker
              type="date"
              :placeholder="$t('SPOTS.KILOWATT_DATE')"
              format="dd MMM yyyy"
              value-format="yyyy-MM-dd"
              :clearable="true"
              v-model="spot.kilo_watt_measure_date"
              @change="onDateChanged"
            />
          </base-input>
          <validation-error
            :errors="apiValidationErrors.kilo_watt_measure_date"
          />
        </div>
      </div>
      <!-- endregion -->

      <div class="add-form-inner">
        <h2>{{ $t("SPOTS.SPOT_EQUIPMENTS") }}</h2>

        <div class="equipments-item">
          <div
            v-for="category in equipmentCategories.filter(
              (item) => item.equipments.length > 0
            )"
            :key="category.id"
            class="equipments-item-inner"
          >
            <h3>{{ category.name }}</h3>

            <div class="checkbox-group">
              <el-checkbox
                :value="allSelected(category.equipments)"
                @change="
                  (selected) => {
                    selected
                      ? selectAll(category.equipments)
                      : unSelectAll(category.equipments);
                    onFormChanged();
                  }
                "
              >
                {{ $t("COMMON.SELECT_ALL") }}
              </el-checkbox>
              <el-checkbox
                v-for="equipment in category.equipments"
                :key="equipment.id"
                :value="isSelected(equipment)"
                :label="equipment.name"
                @change="
                  (selected) => {
                    selected ? select(equipment) : unSelect(equipment);
                    onFormChanged();
                  }
                "
              ></el-checkbox>
            </div>
          </div>
        </div>
      </div>

      <div class="add-form-inner">
        <h2>{{ $t("COMMON.SPOTS_MAP") }}</h2>
        <spot-map-selector
          :filteredSpot="spot"
          @positionChanged="
            (x, y) => {
              spot.map_position_x = x;
              spot.map_position_y = y;
            }
          "
        />
      </div>

      <div class="submit-wrapper">
        <base-button class="btn cancel" type="button" @click="onCloseSpotModal">
          {{ $t("COMMON.CANCEL") }}
        </base-button>
        <base-button type="button" class="btn btn-sm elite-submit" native-type="submit" :disabled="loading || processing">
          <i class="fas fa-spinner fa-spin" v-if="loading || processing" />
          {{ actionBtnLabel }}
        </base-button>
      </div>
    </form>

    <modal :show="showSpotCheckModal" modal-classes="modal-secondary" size="lg">
      <div class="" slot="header">
        <h2 id="">
          {{ $t("COMMON.CONFIRMATION") }}
        </h2>
      </div>
      <div class="">
        <div class="row">
          <p class="text-lg text-center">
            {{
              $t("SPOTS.SAME_SPOTS", {
                name: spot.name,
              })
            }}
          </p>
        </div>
        <div class="page-wrapper-table-body">
          <div class="page-wrapper-table-body-inner">
            <el-table
              class="align-items-center table-flush"
              header-row-class-name="thead-light"
              :data="spots"
            >
              <div class="table-loading" slot="empty" v-if="loading">
                <img src="/img/loading.gif" alt="" />
              </div>
              <el-table-column
                :label="$t('COMMON.NAME')"
                min-width="250"
                prop="name"
              >
                <template v-slot="{ row }">
                  <span>
                    {{ row.name }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                :label="$t('SPOTS.SPOT_CAPACITY')"
                prop="capacity"
                min-width="100"
              />
              <el-table-column
                :label="$t('SPOTS.SPOT_LENGTH')"
                prop="equipment_length"
                min-width="100"
              />
              <el-table-column min-width="50">
                <div slot-scope="{ row }" class="table-actions">
                  <el-tooltip
                    :content="$t('COMMON.VIEW')"
                    placement="top"
                    v-if="$currentUserCan($permissions.PERM_VIEW_SPOTS)"
                  >
                    <a
                      type="text"
                      class="table-action"
                      data-toggle="tooltip"
                      style="cursor: pointer"
                    >
                      <router-link :to="$objectViewRoute(row)">
                        <i class="fal fa-expand-alt"></i>
                      </router-link>
                    </a>
                  </el-tooltip>
                </div>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <template slot="footer">
        <button
          type="submit"
          class="btn btn-primary"
          @click.prevent="continueFormSubmitProcess"
          :disabled="loading || processing"
        >
          <span v-if="loading || processing" class="btn-inner--icon">
            <i class="fas fa-spinner fa-spin"></i>
          </span>
          {{ $t("COMMON.CONTINUE") }}
        </button>

        <button
          type="button"
          class="btn btn-link ml-auto"
          @click="toggleSpotCheckModalState"
          :disabled="loading || processing"
        >
          {{ $t("COMMON.CANCEL") }}
        </button>
      </template>
    </modal>

    <!--<global-loading :loading="true" />-->
  </div>
</template>
<script>
import { cloneDeep } from "lodash";
import {
  Checkbox,
  DatePicker,
  Image,
  Input,
  Option,
  Select,
  Table,
  TableColumn,
} from "element-ui";
import {
  ALLOWED_CUSTOMER_EQUIPMENT,
  FORM_TYPE_LAND,
  FORM_TYPE_PROPERTY,
  FORM_TYPE_SEASONAL,
  spotConditionOptions,
  SPOT_STATUS_AVAILABLE,
  SPOT_STATUS_NOT_AVAILABLE,
  QUERY_ACTIONS_EDIT,
  QUERY_ACTIONS_ADD
} from "@/constants/common";
import swal from "sweetalert2";
import formMixin from "@/mixins/form-mixin";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import SpotCategorySelector from "@/components/SpotCategorySelector.vue";
import SpotTypeSelector from "@/components/SpotTypeSelector.vue";
import ImageSelector from "@/components/ImageSelector.vue";
import GallerySelector from "@/components/GallerySelector.vue";
import ServiceIncludedSelector from "@/components/ServiceIncludedSelector.vue";
import ElectricitySelector from "@/components/ElectricitySelector.vue";
import AllowedEquipmentSelector from "@/components/AllowedEquipmentSelector.vue";
import SpotMapSelector from "@/components/SpotMapSelector.vue";
import store from "@/store";

export default {
  layout: "DashboardLayout",

  components: {
    BaseInput,
    ValidationError,
    OrganizationSelector,
    SpotCategorySelector,
    SpotTypeSelector,
    ImageSelector,
    GallerySelector,
    HtmlEditor,
    flatPicker,
    ServiceIncludedSelector,
    ElectricitySelector,
    AllowedEquipmentSelector,
    SpotMapSelector,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Checkbox.name]: Checkbox,
    [Input.name]: Input,
    [Image.name]: Image,
    [DatePicker.name]: DatePicker,
  },

  mixins: [formMixin],

  props: ["spotData", "formErrors", "loading", "hideOrganization", "action"],

  data() {
    this.fetchEquipmentCategories();
    this.fetchSpotCategory();

    return {
      spot: this.spotData,
      spotStatusOptions: {
        [SPOT_STATUS_AVAILABLE]: "AVAILABLE",
        [SPOT_STATUS_NOT_AVAILABLE]: "NOT_AVAILABLE",
      },
      spotConditionOptions,
      equipmentCategories: [],
      spots: [],
      total: 0,
      showModal: false,
      showSpotCheckModal: false,
      processing: false,
      selectedCategory: null,
      FORM_TYPE_LAND,
      FORM_TYPE_PROPERTY,
      ALLOWED_CUSTOMER_EQUIPMENT,
    };
  },

  created() {},

  mounted() {},

  computed: {
    islandFormType() {
      return this.selectedCategory?.form_type === FORM_TYPE_LAND;
    },
    isPropertyFormType() {
      return this.selectedCategory?.form_type === FORM_TYPE_PROPERTY;
    },
    isSeasonalFormType() {
      return this.selectedCategory?.form_type === FORM_TYPE_SEASONAL;
    },

    actionBtnLabel() {
      if(this.action === QUERY_ACTIONS_EDIT) {
        return this.$t("COMMON.EDIT") ;
      }
      else if(this.action === QUERY_ACTIONS_ADD) {
        return this.$t("COMMON.ADD_ITEM");
      }

      return '';
    },

    bedsTotalQty() {
      return (
        Number(this.spot.beds_king_qty) +
        Number(this.spot.beds_queen_qty) +
        Number(this.spot.beds_double_qty) +
        Number(this.spot.beds_twin_qty)
      );
    },
  },

  methods: {
    async handleSubmit() {
      if (!this.spot.map_position_x || !this.spot.map_position_y) {
        await swal.fire({
          text: this.$t("SPOTS.THE_SPOT_MUST_BE_PUT_ON_MAP"),
          type: "question",
          customClass: {
            popup: "delete-popup",
          },
          buttonsStyling: false,
          showCancelButton: false,
          cancelButtonText: this.$t("COMMON.OK"),
          confirmButtonClass: "btn btn-primary",
          cancelButtonClass: "btn btn-warning",
        });
        return;
      }

      await this.sendInformation();
    },

    async sendInformation() {
      let spotData = cloneDeep(this.spot);
      spotData = this.$fillUserOrganizationData(spotData);
      spotData.gallery = spotData.gallery.filter((item) => !!item);
      if(this.isSeasonalFormType) {
        spotData.departure_time = null;
        spotData.arrival_time = null;
      }

      this.$emit("spotSubmitted", spotData);
    },

    onDateChanged(date) {
      this.spot.kilo_watt_measure_date = date;
      this.spot.kilo_watt = 0;
      this.onFormChanged();
    },

    onFormChanged() {
      this.$emit("formChanged");
    },

    onCategorySelected(categoryId) {
      this.spot.category.id = categoryId;
      this.onFormChanged();
      this.fetchSpotCategory();
    },

    async getList() {
      if (!this.spot?.name) {
        return;
      }
      try {
        this.processing = true;
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...{
              search: `${this.spot?.name}`,
            },
          },
          page: {
            number: 1,
            size: 999,
          },
        };

        if (this.spot?.organization?.id) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              organization: this.spot?.organization?.id,
            },
          };
        }
        await this.$store.dispatch("spots/list", params);
        this.spots = this.$store.getters["spots/list"];
        this.total = this.$store.getters["spots/listTotal"];
        this.processing = false;
      } catch (error) {
        this.processing = false;
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    unSelect(equipment) {
      this.spot.other_equipments = this.spot.other_equipments.filter(
        (eq) => eq.id !== equipment.id
      );
    },

    select(equipment) {
      if (!this.isSelected(equipment)) {
        this.spot.other_equipments.push(equipment);
      }
    },

    isSelected(equipment) {
      let selected = false;

      this.spot.other_equipments.forEach((eq) => {
        if (eq.id === equipment.id) {
          selected = true;
        }
      });

      return selected;
    },
    unSelectAll(equipmentList) {
      equipmentList.forEach((eq) => this.unSelect(eq));
    },

    selectAll(equipmentList) {
      equipmentList.forEach((eq) => this.select(eq));
    },

    allSelected(equipmentList) {
      const unselected = equipmentList.filter(
        (equipment) => !this.isSelected(equipment)
      );

      return unselected.length === 0;
    },

    async fetchSpotCategory() {
      const spotCategoryId = this.spot?.category?.id
        ? this.spot.category.id
        : this.spotData.category.id;

      if (!spotCategoryId) {
        return;
      }

      this.processing = true;

      try {
        await this.$store.dispatch("spotCategories/get", spotCategoryId);
        this.selectedCategory =
          this.$store.getters["spotCategories/spotCategory"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }

      this.processing = false;
    },

    async fetchEquipmentCategories() {
      try {
        this.processing = true;
        let params = {
          page: {
            number: 1,
            size: 999,
          },
          include: "equipments",
        };

        let me = store.getters["profile/me"];
        const org = me?.organization || store.getters["organizations/globalOrganization"];
        if (org?.id) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              organization: org?.id,
            },
          };
        }

        await this.$store.dispatch("equipmentCategories/list", params);
        this.equipmentCategories =
          this.$store.getters["equipmentCategories/list"];
        this.processing = false;
      } catch (error) {
        this.processing = false;
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async checkSpotUniqueInformation() {
      await this.getList();
      switch (this.total) {
        case 0:
          return true;
        case 1:
          return !!this.spot?.id;
        default:
          return false;
      }
    },

    async continueFormSubmitProcess() {
      this.processing = true;
      await this.toggleSpotCheckModalState();
      await this.sendInformation();
    },
    async toggleSpotCheckModalState() {
      this.showSpotCheckModal = !this.showSpotCheckModal;
    },

    async toggleModalState() {
      this.showModal = !this.showModal;
    },

    onCloseSpotModal() {
      this.$emit("onCloseSpotModal");
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    spotData(spotData) {
      if (spotData) {
        this.spot = {
          ...this.spot,
          ...cloneDeep(spotData),
        };
      }
    },
    bedsTotalQty(value) {
      this.spot.beds_total_qty = value;
    },
  },
};
</script>
