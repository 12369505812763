<template>
  <div class="container-fluid">
    <div class="page-wrapper">
      <div slot="header" class="page-wrapper-header">
        <div class="page-wrapper-header-left spot">
          <div class="kw-back">
            <h3>
              {{ `${$t("SPOTS.SPOT_LIST")}` }}
            </h3>
          </div>
          <div class="kw-breadcrumb">
            <ul>
              <li>
                <img src="/img/kw-home.svg" alt="icon" />
                <span>
                  {{ `${$t("SPOTS.SPOT_LIST")}` }}
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div v-if="globalOrganization?.id" class="page-wrapper-header-right">
          <base-button
            class="kw-button add"
            icon
            size="sm"
            @click="openSpotCreateModal"
            v-if="$currentUserCan($permissions.PERM_CREATE_SPOTS)"
          >
            <span class="btn-inner--icon">
              <i class="far fa-plus"></i>
            </span>
            <span class="btn-inner--text">{{ $t("SPOTS.ADD_SPOT") }}</span>
          </base-button>
        </div>
      </div>
      <div v-if="globalOrganization?.id">
        <spot-list-table
          ref="table"
          @onViewSpot="openSpotViewModal"
          @onEditSpot="openSpotEditModal"
          @onDeleteSpot="deleteSpot"
          @onAddSpot="openSpotCreateModal"
          @onCleanSpot="cleanSpot"
          :key="renderKey * 100"
        />

        <!-- region details -->
        <div
          v-if="isViewSpotModalOpened"
          class="resizable-wrapper"
          v-bind:class="[isViewSpotModalOpened ? 'show' : 'hide']"
          :key="renderKey * 200"
        >
          <div
            class="resizable-wrapper-content"
            v-resizable-modal="'SPOT_VIEW'"
          >
            <div class="resizable-wrapper-content-header">
              <div class="resizable-wrapper-content-header-left">
                <button @click="closeSpotModal">
                  <i class="far fa-arrow-left"></i>
                </button>
                <h1>{{ $t("SPOTS.SPOT_DETAILS") }}</h1>
              </div>
              <div class="resizable-wrapper-content-header-right">
                <wrapper-expand-button></wrapper-expand-button>
              </div>
            </div>
            <div class="resizable-wrapper-content-body">
              <view-spot-component
                v-if="openSpot"
                :spotId="openSpot.id"
                @onEditSpot="openSpotEditModal"
                @onDeleteSpot="deleteSpot"
                @onReopen="reopenSpot"
                @onCleanSpot="cleanSpot"
                @onSpotDuplicated="reloadTable"
              />
            </div>
          </div>
        </div>
        <!-- endregion -->

        <!-- region edit -->
        <div
          v-if="isEditSpotModalOpened"
          class="resizable-wrapper"
          v-bind:class="[isEditSpotModalOpened ? 'show' : 'hide']"
          :key="renderKey * 300"
        >
          <div class="resizable-wrapper-content" v-resizable-modal="'SPOT'">
            <div class="resizable-wrapper-content-header">
              <div class="resizable-wrapper-content-header-left">
                <button @click="closeSpotModal">
                  <i class="far fa-arrow-left"></i>
                </button>
                <h1>
                  {{ $t("SPOTS.EDIT_SPOT") }}
                </h1>
              </div>
              <div class="resizable-wrapper-content-header-right">
                <wrapper-expand-button></wrapper-expand-button>
              </div>
            </div>
            <div class="resizable-wrapper-content-body">
              <edit-spot-component
                v-if="openSpot"
                :spotId="openSpot.id"
                @onViewSpot="openSpotViewModal"
                @onCloseSpotModal="closeSpotModal"
              />
            </div>
          </div>
        </div>
        <!-- endregion -->

        <!-- region add -->
        <div
          v-if="isAddSpotModalOpened"
          class="resizable-wrapper"
          v-bind:class="[isAddSpotModalOpened ? 'show' : 'hide']"
          :key="renderKey * 400"
        >
          <div class="resizable-wrapper-content" v-resizable-modal="'SPOT'">
            <div class="resizable-wrapper-content-header">
              <div class="resizable-wrapper-content-header-left">
                <button @click="closeSpotModal">
                  <i class="far fa-arrow-left"></i>
                </button>
                <h1>
                  {{ $t("SPOTS.ADD_SPOT") }}
                </h1>
              </div>
              <div class="resizable-wrapper-content-header-right">
                <wrapper-expand-button></wrapper-expand-button>
              </div>
            </div>
            <div class="resizable-wrapper-content-body">
              <add-spot-component
                @onViewSpot="openSpotViewModal"
                @onCloseSpotModal="closeSpotModal"
              />
            </div>
          </div>
        </div>
        <!-- endregion -->
      </div>
      <select-an-organization-message v-else />
    </div>
  </div>
</template>
<script>
import swal from "sweetalert2";
import { Button } from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import {
  QUERY_ACTIONS_VIEW,
  QUERY_ACTIONS_EDIT,
  QUERY_ACTIONS_ADD,
} from "@/constants/common";
import permissions from "@/constants/permissions";
import SpotListTable from "./partials/SpotListTable.vue";
import EditSpotComponent from "./components/EditSpotComponent.vue";
import AddSpotComponent from "./components/AddSpotComponent.vue";
import ViewSpotComponent from "./components/ViewSpotComponent.vue";
import WrapperExpandButton from "@/components/WrapperExpandButton";
import { mapGetters } from "vuex";
import { CONDITION_CLEAN, CONDITION_DIRTY } from "@/constants/spots";
import SelectAnOrganizationMessage from "@/components/SelectAnOrganizationMessage.vue";

export default {
  layout: "DashboardLayout",

  components: {
    SelectAnOrganizationMessage,
    WrapperExpandButton,
    SpotListTable,
    EditSpotComponent,
    AddSpotComponent,
    ViewSpotComponent,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  computed: {
    ...mapGetters("organizations", {
      globalOrganization: "globalOrganization",
    }),
    ...mapGetters("organizationOptions", {
      organizationConfig: "organizationConfig",
    }),
  },

  watch: {},

  data() {
    const spotId = this.$route.query.id;
    const action = this.$route.query.action;
    let isViewSpotModalOpened = false;
    let isEditSpotModalOpened = false;
    let isAddSpotModalOpened = false;
    let openSpot = null;
    if (spotId && action) {
      if (action === QUERY_ACTIONS_VIEW) {
        isViewSpotModalOpened = true;
      } else if (action === QUERY_ACTIONS_EDIT) {
        isEditSpotModalOpened = true;
      }
      openSpot = { id: spotId };
    } else if (action === QUERY_ACTIONS_ADD) {
      isAddSpotModalOpened = true;
    }
    return {
      isViewSpotModalOpened: isViewSpotModalOpened,
      isEditSpotModalOpened: isEditSpotModalOpened,
      isAddSpotModalOpened: isAddSpotModalOpened,
      openSpot: openSpot,
      renderKey: 1,
    };
  },

  mounted() {},

  methods: {
    openSpotCreateModal() {
      this.closeSpotModal();
      this.isAddSpotModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Spots",
          query: { action: QUERY_ACTIONS_ADD },
        }).href
      );
    },

    openSpotViewModal(spot, reRender = false) {
      this.closeSpotModal();
      this.openSpot = spot;
      this.isViewSpotModalOpened = true;
      if (reRender) {
        this.renderKey++;
      }

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Spots",
          query: { id: this.openSpot.id, action: QUERY_ACTIONS_VIEW },
        }).href
      );
    },

    openSpotEditModal(spot) {
      this.closeSpotModal();
      this.openSpot = spot;
      this.isEditSpotModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Spots",
          query: { id: this.openSpot.id, action: QUERY_ACTIONS_EDIT },
        }).href
      );
    },

    closeSpotModal() {
      this.isAddSpotModalOpened = false;
      this.isViewSpotModalOpened = false;
      this.isEditSpotModalOpened = false;
      this.openSpot = null;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Spots",
          query: {},
        }).href
      );
    },

    reopenSpot(spot) {
      this.closeSpotModal();
      this.renderKey++;
      this.openSpotEditModal(spot);
    },

    async deleteSpot(spot) {
      const confirmation = await swal.fire({
        text: this.$t("SPOTS.DELETE_THIS_SPOT"),
        type: "question",
        customClass: {
          popup: "delete-popup",
        },
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: this.$t("COMMON.CANCEL"),
        confirmButtonText: this.$t("COMMON.DELETE_ITEM"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      const deleteConform = await swal.fire({
        text: this.$t("SPOTS.DELETE_SPOT_SUCCESS"),
        type: "info",
        customClass: {
          popup: "delete-confirm",
        },
        buttonsStyling: false,
        showCancelButton: false,
        confirmButtonText: this.$t("COMMON.END"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("spots/destroy", spot.id);
          this.renderKey++;
          this.closeSpotModal();
          deleteConform;
          /* this.$notify({
            type: "success",
            message: this.$t("SPOTS.SPOT_DELETED"),
          }); */
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async cleanSpot(spotId, cleanCondition) {
      let conditionSwitch = CONDITION_DIRTY;
      if (cleanCondition === CONDITION_DIRTY) {
        conditionSwitch = CONDITION_CLEAN;
      }

      const confirmation = await swal.fire({
        title: this.$t(`SPOTS.${conditionSwitch}_THIS_SPOT`),
        type: "question",
        customClass: {
          popup: "default-popup",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.isConfirmed) {
          swal.showLoading();
          await this.$store.dispatch(
            `spots/${conditionSwitch.toLowerCase()}`,
            spotId
          );
          this.renderKey++;
          this.$notify({
            type: "success",
            message: this.$t(`SPOTS.SPOT_${conditionSwitch}`),
          });
          //await this.$refs.table.getList();
        }
        swal.close();
      } catch (error) {
        console.log(error);
        swal.close();
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async reloadTable() {
      // this.renderKey++;
      // await this.$refs.table.getList();
    },
  },
};
</script>
